<template>
  <section>
    <vx-card class="mb-8" title="General">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/${form.permalink}`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <div class="mb-2">
        <vs-input v-model="form.title" class="w-full" label="Titlul" placeholder="Titlul Paginii"/>
        <error-messages :errors="errors.title"></error-messages>
      </div>
      <div class="mb-6">
        <vs-input v-model="form.permalink" :disabled="true" class="w-full" label="" placeholder="" size="small" />
      </div>
      <page-body v-model="form.body" class="mb-6" v-bind="propsToPass()">
        <error-messages :errors="errors.body"/>
      </page-body>
    </vx-card>
    <vx-card class="mb-8" title="SEO">
      <div class="mb-6">
        <vs-input v-model="form.meta_title" class="w-full" label="Titlul Meta" placeholder="Titlul Meta"/>
        <error-messages :errors="errors.meta_title"></error-messages>
      </div>
      <div class="mb-6">
        <vs-input v-model="form.meta_description" class="w-full" label="Descriere Meta" placeholder="Descrierea meta"/>
        <error-messages :errors="errors.meta_description"></error-messages>
      </div>
    </vx-card>
    <vx-card class="mb-8" title="Actiuni">
      <vs-button class="mr-2 primary-button" color="primary" type="filled" @click="savePage">Salveaza</vs-button>
    </vx-card>

  </section>
</template>

<script>
import PageBody from '@/views/PageManagement/components/PageBody'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'EditPage',
  components: {ErrorMessages, PageBody},
  data () {
    return {
      form: {
        body: '',
        title: '',
        meta_title: '',
        meta_description: ''
      },
      errors: {}
    }
  },
  computed: {
    pageId () {
      return this.$route.params.page
    },
    franchisee_id() {
      return this.$store.getters['auth/currentUser'].franchisee_id
    },
    franchisee() {
      return this.$store.getters['singleFranchisee/item']
    }
  },
  methods: {
    propsToPass() {
      const result = {};

      if (this.franchisee && this.franchisee.tinymce_api_key) {
        result.apiKey = this.franchisee.tinymce_api_key;
      } else {
        result.apiKey = '4w2lsg0k465wm60ocfih1f34d32ziejr07pit79e8mopcrta';
      }

      return result
    },    
    savePage () {
      this.errors = {}
      this.$http.put(`/pages/${this.pageId}`, this.form)
        .then(() => {
          this.$router.push({name: 'settings-page-management'})
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              'color': 'danger'
            })
          } else {
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              'color': 'danger'
            })
          }
        })
    },
    NavigationLink(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    loadFranchisee() {
      this.franchisee_id && this.$store.dispatch('singleFranchisee/load', this.franchisee_id)
    }
  },
  created () {
    this.loadFranchisee()
    this.$http.get(`pages/${this.pageId}`)
      .then(({data}) => {
        this.form = data
      })
      .catch(() => {
        this.$vs.notify({
          'title': 'Eroare',
          'text': 'Eroare la incarcare, va rugam reimprospatati pagina sau incercati mai tarziu',
          'color': 'danger'
        })
      }
      )
  }
}
</script>
